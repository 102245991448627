export const types = [
    {
      id: 1,
      name: "Lawnmower"
    },
    {
      id: 2,
      name: "Phone Case"
    },
    {
      id: 3,
      name: "T-Shirt"
    }
];

export function getTypes() {
  return types;
}
